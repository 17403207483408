<template>
    <div class="articles">
        <banner></banner>
        <div class="site-content animate">
            <vue-easy-lightbox
                escDisabled
                moveDisabled
                :visible="visible"
                :imgs="imgs"
                @hide="handleHide"
            ></vue-easy-lightbox>
            <!-- 文章目录 -->
            <div id="article-menus" ref="firstDiv">
                <menu-tree :menus="menus" child-label="child"></menu-tree>
            </div>
            <main class="site-main" ref="secondDiv">
                <article class="hentry">
                    <!-- 文章头部 -->
                    <header class="entry-header">
                        <!-- 标题输出 -->
                        <h1 class="entry-title">{{blog.blogTitle}}</h1>
                        <hr>
                        <div class="breadcrumbs">
                            <div id="crumbs"><span style="color:gray">{{blog.userNikename}}</span> ● 发布时间：{{blog.pubTime | dataFormat}}</div>
                        </div>
                    </header>


                    <!-- 正文输出 -->
                    <div class="entry-content" style="overflow-x: hidden" @click="handleImageClick">
                      <!-- 图片封面 -->
                      <img :src="this.picServer + blog.titlePage"/>
                      <p ref="content" v-html="blog.blogContent"></p>
                    </div>
                    <!-- 文章底部 -->
                    <section-title>
                        <footer class="post-footer">
                            <!-- 阅读次数 -->
                            <div class="post-like">
                                <i class="iconfont iconeyes"></i>
                                <span class="count">{{blog.viewCount}}</span>
                            </div>
                            <!--<div class="donate" @click="showDonate=!showDonate">
                                <span>赏</span>
                                <ul class="donate_inner" :class="{'show':showDonate}">
                                    <li class="wedonate"><img :src="picServer + 'mainPic/weixin_pay.png'"><p>微信</p></li>
                                    <li class="alidonate"><img :src="picServer + 'mainPic/ali_pay.png'"><p>支付宝</p></li>
                                </ul>
                            </div>-->
                            <!-- 文章标签 -->
                            <div class="post-tags">
                                <i class="iconfont iconcategory"></i>
                               <router-link :to="`/category/${blog.typeName}`">{{ blog.typeName }}</router-link>
                            </div>
                        </footer>
                    </section-title>

                    <!--声明-->
                    <!--<div class="open-message">
                        <p>声明：Memory博客 | 版权所有，违者必究 | 如未注明，均为原创 | 本网站采用<a href="https://creativecommons.org/licenses/by-nc-sa/3.0/" target="_blank">BY-NC-SA</a>协议进行授权</p>
                        <p>转载：转载请注明原文链接 - <a href="#">{{blog.blogTitle}}</a></p>
                    </div>-->
                    <!--评论-->

                    <div class="comments">
                        <div v-if="showInput">
                            <comment-message-editor :inline="true" buttonText="发布评论"  @submit="submitReply" ></comment-message-editor>
                        </div>
                        <comment v-for="item in comments" :key="item.comment.id" :comment="item.comment" :blogId="blogId" :blogUserId="blog.userId" v-on:refresh="getComment">
                            <template v-if="item.reply.length">
                                <comment v-for="reply in item.reply" :key="reply.id" :comment="reply" :blogId="blogId" :blogUserId="blog.userId" v-on:refresh="getComment"></comment>
                            </template>
                        </comment>
                    </div>
                </article>
            </main>
        </div>
    </div>
</template>

<script>
    import commentMessageEditor from 'comment-message-editor'
    import Banner from '@/components/banner'
    import sectionTitle from '@/components/section-title'
    import comment from '@/components/comment'
    import menuTree from '@/components/menu-tree'
    import {fetchComment} from '../api'
    import router from "../router";
    import hljs from 'highlight.js'; // 引入高亮插件
    import 'highlight.js/styles/monokai-sublime.css'; // 引入高亮样式
    import VueEasyLightbox from 'vue-easy-lightbox'
    export default {
        name: 'articles',
        data(){
            return{
                imgs: '',  // Img Url , string or Array of string
                visible: false,
                blogId: this.$route.params.id,
                showDonate: false,
                comments: [],
                menus: [],
                blog:{},
                showInput:  true,
                commentForm:{
                    blogId: '',
                    parentId: '',
                    toUserId: '',
                    content: ''
                },
            }
        },
        components: {
            Banner,
            sectionTitle,
            comment,
            commentMessageEditor,
            menuTree,
            VueEasyLightbox
        },
        methods: {
            handleImageClick(event) {
              // 判断被点击的元素是否是 img 标签
              if (event.target.tagName === 'IMG') {
                // 获取被点击图片的 src 属性
                const clickedImgSrc = event.target.src;
                // 进行相关操作，比如设置到数据中或打开 lightbox
                console.log('Clicked image source:', clickedImgSrc);
                this.imgs = [clickedImgSrc]
                // 或者传递一个图片配置对象
                this.show()
              }
            },
            show() {
              this.visible = true
            },
            handleHide() {
              this.visible = false
            },

            toast(variant, message) {
                this.counter++;
                this.$bvToast.toast(message, {
                    title: `Toaster`,
                    toaster: 'b-toaster-top-right',
                    variant: variant,
                    solid: true,
                    appendToast: false
                });
            },
            async getComment(id){
                const {data: res} = await this.$blog.post('/home/comments/getComments',{paegNo:1,pageSize:10,blogId:id})
                this.comments = res.data;
            },
            fetchH(arr,left,right){
                if (right) {
                    return arr.filter(item => item.offsetTop > left && item.offsetTop < right)
                }else {
                    return arr.filter(item => item.offsetTop > left)
                }
            },
            async getContent(id){
                const {data: res} = await this.$blog.get('/home/blog/getContent/' + id)
                this.blog = res.data;
                this.$nextTick(()=>{
                    this.updateHTagsWithId();
                    this.createMenus();
                    hljs.highlightAll();
                })

            },
            updateHTagsWithId() {
              const contentElement = this.$refs.content;
              // 查找所有 <h> 标签
              const hTags = contentElement.querySelectorAll('h1, h2, h3, h4, h5, h6');
              // 更新标签
              hTags.forEach((tag, index) => {
                // 将 innerText 设置为 id
                tag.id = `article/` +this.blog.id+ `#` + tag.innerHTML;
              });
            },
            // 生成目录
            createMenus(){
                let arr = []
                for(let i=6;i>0;i--){
                    let temp = []
                    let e = document.querySelector(".entry-content").querySelectorAll(`h${i}`)
                    for (let j=0;j<e.length;j++){
                        let child = this.fetchH(arr,e[j].offsetTop,(j+1 === e.length)?undefined:e[j+1].offsetTop)
                        temp.push({
                            h: i,
                            title: e[j].innerText,
                            id: e[j].id,
                            offsetTop: e[j].offsetTop,
                            child
                        })
                    }
                    if (temp.length){
                        arr = temp
                    }
                }
                this.menus = arr
            },
            submitReply(v){
                if (sessionStorage.getItem('user') == null || sessionStorage.getItem('user') == undefined){
                    // alert('请您登录后再发表评论！');
                    this.toast('danger','请您登录后再发表评论！');
                    return;
                }
                this.commentForm.blogId = this.blog.id;
                this.commentForm.toUserId = this.blog.userId;
                this.commentForm.parentId = 0;
                this.commentForm.content = v;
                console.log(this.commentForm)
                this.sendCommentRequest();
                this.showInput = false;
            },
            async sendCommentRequest(){
                const {data: res} = await this.$blog.post('/comments/reply',this.commentForm)
                if (res.code == 200) {
                    this.toast(res.success?'success':'danger','评论成功');
                } else {
                    this.toast(res.success?'success':'danger',res.msg);
                }
                // this.$refs.aaaaa.inputContent = '';
                // console.log(this.$refs.aaaaa)
                this.showCommentEditor = false
                this.commentForm.content = '';
                this.getComment(this.blog.id)
                this.showInput = true;
            },
        },
        mounted(){
            if (typeof this.$route.query !== 'undefined'){
                this.getContent(this.$route.params.id);
            }
            // 获取第一个 div 的高度
            setTimeout(() => {
              const firstDiv = document.getElementById('article-menus');
              const firstDivHeight = firstDiv.offsetHeight;
              // 设置第二个 div 的 margin-top 为第一个 div 的高度
              const secondDiv = this.$refs.secondDiv; // 假设第二个 div 使用 ref 属性为 "secondDiv"
              if (secondDiv) {
                secondDiv.style.marginTop = `${-firstDivHeight}px`; // 设置第二个 div 的 margin-top
              }
            }, 200);

        },
        created() {
            this.getComment(this.$route.params.id)
        }
    }
</script>
<style>
    .content.text[data-v-30cdf531] {
        min-height: 4.2em;
    }
</style>
<style scoped lang="less">
    .site-content {
        position: relative;
        .site-main {
            padding: 80px 0 0 0;
        }
    }
    #article-menus{
        position: sticky;
        top: 0;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
        border-radius: 3px;
        padding: 15px;
        width: 300px;
        transform: translateX(-120%) translateY(150px);
        font-size: 14px;
    }
    article.hentry {
        .entry-header {
            .entry-title {
                font-size: 23px;
                font-weight: 600;
                color: #737373;
                margin: 0.67em 0;

                &:before {
                    content: "#";
                    margin-right: 6px;
                    color: #d82e16;
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            hr {
                height: 1px;
                border: 0;
                background: #EFEFEF;
                margin: 15px 0;
            }

            .breadcrumbs {
                font-size: 14px;
                color: #D2D2D2;
                text-decoration: none;
                margin-bottom: 30px;
            }
        }

        //.entry-content {}
		.entry-titlePage {}
        footer.post-footer {
            width: 100%;
            padding: 20px 10px;
            margin-top: 30px;
            height: 65px;
            position: relative;
            i{
                font-size: 18px;
                margin-right: 5px;
            }
            .post-like {
                float: right;
                margin: 7px 0 0 20px;
            }

            .post-share {
                float: right;
                list-style: none;
                margin-right: 20px;
            }

            .donate {
                float: left;
                line-height: 36px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border: 1px solid #2B2B2B;
                &:hover{
                    border: 1px solid goldenrod;
                    span{
                        color: goldenrod;
                    }
                }
                span {
                    color: #2B2B2B;
                    padding: 10px;
                    position: relative;
                    cursor: pointer;
                }

                .donate_inner {
                    display: none;
                    margin: 0;
                    list-style: none;
                    position: absolute;
                    left: 80px;
                    top: -40px;
                    background: #FFF;
                    padding: 10px;
                    border: 1px solid #ddd;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, .08);
                    border-radius: 3px;
                    &.show{
                        display: block;
                    }
                    li {
                        float: left;
                    }

                    img {
                        width: 100px;
                    }
                    p {
                        text-align: center;
                        font-size: 15px;
                        color: #D2D2D2;
                        line-height: 1rem;
                    }
                }

                .donate_inner:after, .donate_inner:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 45%;
                    margin-left: -8px;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-right: 8px solid #fff;
                }

                .donate_inner:before {
                    left: -1px;
                    border-right: 8px solid #ddd;
                }

            }

            .post-tags {
                margin: 7px 0 0 20px;
                float: left;
                text-transform: uppercase;
                a:hover{
                    color: #ff6d6d;
                }
            }
        }
        .open-message {
            margin: 50px 0;
            position: relative;
            background: #2B2B2B;
            padding: 10px 30px;
            border-radius: 3px;
            font-size: 14px;
            color: #fff;
            &:after {
                content: "";
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #2B2B2B;
                position: absolute;
                top: -8px;
                left: 48%;
            }
            p {
                margin: 10px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            a {
                color: #A0DAD0;
                padding: 0 5px;
            }
        }
    }
</style>
