<template>
    <div class="comment" :id="`comment${comment.id}`">
        <div class="comment-head">
            <div class="user-avatar"><img :src="comment.fromUserAvatar" alt=""></div>
            <div class="head-right">
                <section-title>
                    <div style="display: flex;align-items: center;justify-content: space-between; font-size:14px">
                        <div>
                            <!--TODO-->
                            <span class="from-user user-name">{{comment.fromUserName}}</span>
                            <img class="is_bloger" v-if="comment.fromUserId == blogUserId" src="@/assets/images/bloger.png"/>
                            <span class="to-user" v-if="comment.toUserId && comment.parentId!=0"><span style="margin: 0 5px;">回复
                                </span><span class="user-name">{{comment.toUserName}}</span>
                                <img class="is_bloger" v-if="comment.toUserId == blogUserId" src="@/assets/images/bloger.png"/>
                            </span>
                        </div>
                        <div style="font-size: 13px;">
                            <span style="color: #9c9c9c;margin-right: 20px;">{{comment.createTime | dataFormat3}}</span>
                            <span @click.stop="addComment" style="cursor: pointer;">回复</span>
                        </div>
                    </div>
                </section-title>
            </div>
        </div>
        <div class="comment-body">
            <div class="content-text">
                <p v-html="comment.content">{{comment.content}}</p>
            </div>
            <div v-if="showCommentEditor" @click.stop="">
                <comment-message-editor :inline="true" buttonText="回复" @submit="submitReply"></comment-message-editor>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import sectionTitle from '@/components/section-title'
    import commentMessageEditor from 'comment-message-editor'
    export default {
        name: "comment",
        props: {
            comment: {
                type: Object
            },
            blogId: { type: Number, required: true },
            blogUserId: { type: Number, required: true },
        },
        data(){
            return{
                commentForm:{
                    blogId: '',
                    parentId: '',
                    toUserId: '',
                    content: ''
                },
                showCommentEditor: false
            }
        },
        watch:{
            showCommentEditor(value) {
                if (value) {
                    document.body.addEventListener('click', this.close)
                } else {
                    document.body.removeEventListener('click', this.close)
                }
            },
        },
        components: {
            sectionTitle,
            commentMessageEditor
        },
        methods: {
            toast(variant, message) {
                this.counter++;
                this.$bvToast.toast(message, {
                    title: `Toaster`,
                    toaster: 'b-toaster-top-right',
                    variant: variant,
                    solid: true,
                    appendToast: false
                });
            },
            showBloger(comment){
                console.log("comment.toUserId = this.blogUserId",comment.toUserId == this.blogUserId)
                return comment.toUserId == this.blogUserId;
            },
            addComment(comment){

                console.log(this.comment)
                this.commentForm = {
                    blogId: this.blogId,
                    toUserId: this.comment.fromUserId,
                    parentId: this.comment.parentId,
                }
                this.showCommentEditor = true
            },
            reply(id){
                const ref = `comment${id}`
                console.log(ref,"喜喜喜喜喜喜喜喜喜喜")
            },
            submitReply(v){
                if (sessionStorage.getItem('user') == null || sessionStorage.getItem('user') == undefined){
                    // alert('请您登录后再发表评论！');
                    this.toast('danger','请您登录后再发表评论！');
                    return;
                }
                this.commentForm.content = v;
                this.sendCommentRequest();
                console.log(this.commentForm)
            },
            async sendCommentRequest(){
                const {data: res} = await this.$blog.post('/comments/reply',this.commentForm)
                this.showCommentEditor = false
                this.$emit('refresh',this.blogId);
            },
            close(){
                this.showCommentEditor = false
            }
        }
    }
</script>

<style scoped lang="less">
    .is_bloger {
        width: 26px;
        height: 16px;
        vertical-align: middle;
        margin-left: 2px;
        margin-top: -3px;
    }
    .comment{
        margin: 20px 0;
    }
    .comment-head{
        display: flex;
        .head-right{
            flex: 1;
        }
        .user-name{
            color: #8fd0cc;
        }
    }
    .comment-body{
        padding-left: 80px;
        .content-text{
            /*padding-bottom: 30px;*/
            margin-bottom: 30px;
            font-size: 14px;
            color: #9c9c9c;
            line-height: 1.3rem;
        }
    }
    .user-avatar{
        width: 50px;
        height: 50px;
        margin-right: 30px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
    @media (max-width: 600px){
        .comment-body{
            padding-left: 15px;
            .content-text{
                margin-top: 10px;
            }
        }
        .user-avatar{
            margin-right: 10px;
        }
    }
</style>
